<template>
    <section class="fade poppins">
        <section  class=" tw-sticky tw-top-0 tw-z-50 tw-bg-white">
            <img src="/bar-line.png" class="tw-w-full">
            <div class="tw-max-w-6xl tw-mx-auto ">
                <div class="tw-h-20 tw-w-full tw-flex tw-justify-between tw-items-center">
                    <div class="">
                        <v-img src="../../assets/images/logo.png" alt="Logo"
                            contain
                            height="70px"
                            width="70px" 
                        />
                    </div>
                    <div class="tw-flex tw-gap-6 tw-items-center">
                        <div @click="$router.push(`/`)" class="` tw-font-semibold `" v-for="item, i in tabs" :key=i>
                            {{item}}
                        </div>
                    </div>
                    <div class=""></div>
                </div>
            </div>
        </section>
        
        <div class="tw-max-w-5xl tw-mx-auto tw-py-20">
            <div class="tw-flex tw-justify-end tw-py-4">
                <button @click="$router.push(`/`)" style="font-size: 14px" class=" tw-w-16 tw-rounded tw-py-2 tw-font-medium  tw-bg-gray-200 tw-text-gray-700 hover:tw-bg-gray-300">
                    Back
                </button>
            </div>
            <div class="" v-if="Object.keys(announcement).length > 0">
                <img :src="announcement.banner" alt="banner" class="tw-w-full  tw-object-center tw-object-fill " style="min-height: 300px; max-height: 300px">
                <div class="tw-flex tw-flex-col tw-items-center tw-my-5">
                    <div class="tw-px-4 tw-py-2 tw-text-sm tw-font-semibold tw-rounded tw-text-primary tw-bg-light">{{ announcement.type }}</div>
                    <div class="tw-my-4 tw-text-lg tw-font-bold">{{ announcement.title  }}</div>
                    <div class="tw-text-sm tw-font-medium tw-text-gray-500">{{ $dateFormat.mmDDyy(announcement.created_at)  }}</div>
                </div>
                <div class="tw-font-medium tw-text-center tw-text-gray-600 tw-my-7" v-html="announcement.content"></div>
                <div class="tw-grid tw-grid-cols-4 tw-gap-4 tw-place-content-center">
                    <div class="" v-for="item, i in announcement.images" :key="i">
                        <img :src="item.url" alt="image" class="tw-object-cover">
                    </div>
                </div>
            </div>
        </div>

        <div class=" tw-bottom-0 tw-text-white tw-flex tw-items-center tw-justify-center tw-w-full tw-bg-primary" style="height: 250px">
            <div class="tw-max-w-5xl">
                <div class="tw-flex tw-gap-6 tw-items-center">
                    <div class=" tw-font-medium">Home</div>
                    <div class=" tw-font-medium">About MIS HOL</div>
                    <div class=" tw-font-medium">Announcements</div>
                    <div class=" tw-font-medium">MIS HOL Steps</div>
                    <div class=" tw-font-medium">FAQS</div>
                </div>
                <div class="tw-font-medium tw-text-center tw-my-5">Copyright © PSGS 2023. </div>
            </div>
        </div>
        <img src="/bar-line.png" class="tw-w-full">
    </section>
</template>

<script>
import { api } from '@/services/axios'

export default {
    data: () => ({
        announcement: {},
        loading: false,
        tabs: [
            "Home",
            "About MIS HOL",
            "Announcements",
            "MIS HOL Steps",
            "FAQS"
        ],
    }),
    async mounted() {
        await api.get(`/announcement/${this.$route.params.id}`)
            .then((res) => {
                this.announcement = res.data
            })
    },
  
}
</script>